import React from 'react';
import styles from './TopPageBanner.module.scss'
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { ArrowForward } from '@material-ui/icons';
import logo from "./live.svg";

const TopPageBanner = ({ onClose }) => {
  const handleCloseClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (onClose) {
      onClose();
    }
  }

  const handleBannerClick = () => {
    window.open('https://www.cybervisiontech.com/contact-us', '_blank');
  }

  return <div className={styles['top-page-heading']} onClick={handleBannerClick}>
    <div className={styles['top-page-heading-body']}>
      {/* <div className={styles['top-page-heading-logo']}>
        <img src={logo} alt="" />
      </div> */}
      <div className={styles['top-page-heading-flex']}>
        <div className={styles['top-page-heading-message']}>
          {/* <span className={styles['inner-span']}>Webinar</span> */}
          <span>Meet us at MWC25 Barcelona</span>
        </div>
        <div className={styles['top-page-heading-date']}>
          <span className={styles['top-page-heading-date-text']}>March 3-6, 2025</span>
          <span className={styles['top-page-heading-dot']}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
            </svg>
          </span>
          <span className={styles['top-page-heading-time-text']}>Fira Gran Via, Barcelona</span>
        </div>
      </div>
      <div className={styles['top-page-heading-cta']}>
        <Button color={'primary'} variant={'contained'}>
          CONTACT US &nbsp;
          <ArrowForward />
        </Button>
      </div>
    </div>
    <div className={styles['top-page-heading-cta-small']}>
      <Button color={'primary'} variant={'contained'}>
        <ArrowForward />
      </Button>
    </div>
    <CloseIcon className={styles['top-page-heading-close']} onClick={handleCloseClick} />
  </div>
}

export default TopPageBanner;